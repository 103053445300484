/* Work Styles*/

.how__work {
    padding: 138px 0;
    position: relative;
    &::before {
        background: #f2f4f6 none repeat scroll 0 0;
        content: "";
        height: 100%;
        left: 23%;
        position: absolute;
        top: 0;
        width: 320px;
    }
    .thumb {
        left: -135px;
        position: relative;
        text-align: left;
    }
    .content {
        h2 {
            color: #001232;
            font-size: 36px;
            margin-bottom: 20px;
            text-transform: capitalize;
        }
        h6 {
            color: #001232;
            font-size: 18px;
            font-weight: 600;
            line-height: 26px;
            margin-bottom: 31px;
            padding-right: 42%;
        }
        p {
            font-size: 16px;
            margin-bottom: 35px;
        }
    }
}

/* Responsive Css*/

@media #{$lg-layout} {
    .how__work .thumb img {
        width: 100%;
    }
    .how__work .thumb {
        left: 0;
    }
    .how__work::before {
        left: 10%;
    }
    .how__work .content h6 {
        padding-right: 18%;
    }
}

@media #{$md-layout} {
    .how__work::before {
        display: none;
    }
    .how__work .thumb {
        left: 0;
    }
    .how__work .content h6 {
        padding-right: 0;
    }
}

@media #{$sm-layout} {
    .how__work .thumb {
        left: 0;
    }
    .how__work::before {
        display: none;
    }
    .how__work .thumb img {
        width: 100%;
    }
    .how__work .content h6 {
        padding-right: 0;
    }
    .how__work .content h2 {
        font-size: 27px;
        margin-bottom: 13px;
    }
    .how__work .content h6 {
        margin-bottom: 16px;
    }
    .how__work {
        padding: 60px 0;
    }
    .dg__working__process {
        padding: 60px 0;
    }
    
}

@media #{$xs-layout} {}

@media #{$xs-layout} {}

@media #{$xx-layout} {
    .how__work::before {
        left: 19%;
    }
}

@media #{$xlg-layout} {
    .how__work::before {
        display: none;
    }
    .how__work .thumb {
        left: 0;
    }
    .how__work .thumb img {
        width: 100%;
    }
}