/*---------------------
		Footer Styles
-----------------------*/

.dg__footer__container {
  padding-top: 100px;
  padding-bottom: 80px;
}

.footer__widget {
  h4 {
    font-size: 24px;
    font-weight: 700;

    text-transform: uppercase;

    color: #ffffff;
  }
  .footer__inner {
    margin-top: 38px;
    ul {
      padding: 0;

      list-style: outside none none;
      li {
        font-size: 18px;

        margin-bottom: 9px;

        transition: 0.5s;

        color: #8f8f8f;
        &:hover {
          color: $theme-color;
        }
        a {
          font-size: 18px;

          transition: 0.5s;

          color: #8f8f8f;
          &:hover {
            color: $theme-color;
          }
        }
      }
    }
  }
  &.resources {
    margin-left: 34px;
  }
  &.support {
    margin-left: 93px;
  }
  &.information {
    margin-left: 59px;
  }
}

.footer--2 .footer__widget h4 {
  color: #001232;
}

.footer--2 .footer__widget .footer__inner ul li a,
.footer--2 .footer__widget .footer__inner ul li {
  color: #1a1200;
}

.footer--2 .footer__widget .footer__inner ul li a:hover,
.footer--2 .footer__widget .footer__inner ul li:hover {
  color: #f7a901;
}

.footer--2 .copyright .copyright__inner p {
  color: #1a1200;
}

.footer--1 .footer__widget .footer__inner ul li a:hover,
.footer--1 .footer__widget .footer__inner ul li:hover {
  color: #f7a901;
}

/* Copyright */

.copyright {
  padding: 25px 0;
  .footer__right {
    margin: 0;
    padding: 0;

    text-align: right;
  }
  .copyright__inner {
    p {
      font-size: 16px;

      margin: 0;

      color: #888888;
    }
  }
}

.social__icon {
  display: flex;
  justify-content: flex-end;

  list-style: outside none none;
  li {
    margin-left: 30px;
    a {
      svg {
        font-size: 18px;

        transition: 0.3s;

        color: #888888;
      }
      &:hover svg {
        color: #005cff;
      }
    }
  }
}

.footer--2 .social__icon li a svg {
  transition: 0.3s;

  color: #1a1200;
}

.footer--2 .social__icon li a:hover svg {
  color: #005cff;
}

@media #{$lg-layout} {
  .dg__brand__area .space-between {
    padding: 0;
  }
  .footer__widget.resources {
    margin-left: 0;
  }
  .footer__widget.information {
    margin-left: 20px;
  }
  .footer__widget.support {
    margin-left: 34px;
  }
  .footer__widget .footer__inner ul li {
    font-size: 16px;
  }
  .footer__widget .footer__inner ul li a {
    font-size: 16px;
  }
}

@media #{$md-layout} {
  .dg__brand__area .space-between {
    padding: 0;
  }
  .footer__widget.support {
    margin-left: 0;
  }
  .footer__widget.resources {
    margin-left: 0;
  }
  .footer__widget.information {
    margin-left: 0;
  }
}

@media #{$sm-layout} {
  .dg__brand__area .space-between {
    padding: 0;
  }
  .footer__widget.support {
    margin-left: 0;
  }
  .footer__widget.resources {
    margin-left: 0;
  }
  .footer__widget.information {
    margin-left: 0;
  }
}

@media #{$xs-layout} {
  .social__icon {
    justify-content: center;
  }
  .copyright__inner {
    margin-bottom: 14px;

    text-align: center;
  }
  .social__icon li:first-child {
    margin-left: 0;
  }
}
