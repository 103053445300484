/*------------------------
Reset Styles
-----------------------*/

* {
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;

  height: 0;
}

[hidden] {
  display: none;
}

html {
  font-size: 100%;

  overflow-y: scroll;

  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

html,
button,
input,
select,
textarea {
  font-family: 'Source Sans Pro', Helvetica, sans-serif;
}

body {
  line-height: 1.5;

  margin: 0;

  color: #141412;
}

a {
  text-decoration: none;

  color: #212b36;
}

// a:visited {
//   color: #ac0404;
// }

a:focus {
  outline: thin dotted;
}

a:active,
a:hover {
  color: #ea9629;
  outline: 0;
}

a:hover {
  text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Bitter, Georgia, serif;
  line-height: 1.3;

  clear: both;

  color: #1a1200;
}

h1 {
  font-size: 55px;

  margin: 33px 0;
}

h2 {
  font-size: 36px;

  margin: 25px 0;
}

h3 {
  font-size: 22px;

  margin: 22px 0;
}

h4 {
  font-size: 20px;

  margin: 25px 0;
}

h5 {
  font-size: 18px;

  margin: 30px 0;
}

h6 {
  font-size: 16px;

  margin: 36px 0;
}

address {
  font-style: italic;

  margin: 0 0 24px;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

mark {
  color: #000;
  background: #ff0;
}

p {
  margin: 0 0 24px;
}

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 14px;

  -webkit-hyphens: none;
  hyphens: none;

  -ms-hyphens: none;
}

pre {
  font-family: monospace;
  font-size: 14px;

  overflow: auto;

  margin: 20px 0;
  padding: 20px;

  white-space: pre-wrap;
  word-wrap: break-word;

  color: #666;
  background: #f5f5f5;
}

blockquote,
q {
  quotes: none;
  -webkit-hyphens: none;
  hyphens: none;

  -ms-hyphens: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

blockquote {
  font-size: 18px;
  font-weight: 300;
  font-style: italic;

  margin: 24px 40px;
}

blockquote blockquote {
  margin-right: 0;
}

blockquote cite,
blockquote small {
  font-size: 14px;
  font-weight: normal;

  text-transform: uppercase;
}

blockquote em,
blockquote i {
  font-weight: 300;
  font-style: normal;
}

blockquote strong,
blockquote b {
  font-weight: 400;
}

small {
  font-size: smaller;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;

  position: relative;

  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

dl {
  margin: 0 20px;
}

dt {
  font-weight: bold;
}

dd {
  margin: 0 0 20px;
}

menu,
ol,
ul {
  margin: 16px 0;
  padding: 0 0 0 40px;
}

ul {
  list-style-type: square;
}

nav ul,
nav ol {
  list-style: none;
  list-style-image: none;
}

li > ul,
li > ol {
  margin: 0;
}

img {
  vertical-align: middle;

  -ms-interpolation-mode: bicubic;
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

form {
  margin: 0;
}

fieldset {
  min-width: inherit;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;

  border: 1px solid #c0c0c0;
}

legend {
  padding: 0;

  white-space: normal;

  border: 0;
}

button,
input,
select,
textarea {
  font-size: 100%;

  max-width: 100%;
  margin: 0;

  vertical-align: baseline;
}

button,
input {
  line-height: normal;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  cursor: pointer;

  -webkit-appearance: button;
}

button[disabled],
input[disabled] {
  cursor: default;
}

input[type='checkbox'],
input[type='radio'] {
  padding: 0;
}

input[type='search'] {
  /* Don't cut off the webkit search cancel button */
  width: 270px;
  padding-right: 2px;

  -webkit-appearance: textfield;
}

input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;

  border: 0;
}

textarea {
  overflow: auto;

  vertical-align: top;
}

table {
  font-size: 14px;
  line-height: 2;

  width: 100%;
  margin: 0 0 20px;

  border-spacing: 0;
  border-collapse: collapse;

  // border-bottom: 1px solid #ededed;
}

caption,
th,
td {
  font-weight: normal;

  text-align: left;
}

caption {
  font-size: 16px;

  margin: 20px 0;
}

th {
  font-weight: bold;

  text-transform: uppercase;
}

td {
  padding: 6px 10px 6px 0;

  // border-top: 1px solid #ededed;
}

del {
  color: #333;
}

ins {
  text-decoration: none;

  background: #fff9c0;
}

hr {
  height: 1px;
  margin: 0 0 24px;

  border: 0;
  background-size: 4px 4px;
}

/* Placeholder text color -- selectors need to be separate to work. */

::-webkit-input-placeholder {
  color: #7d7b6d;
}

:-moz-placeholder {
  color: #7d7b6d;
}

::-moz-placeholder {
  color: #7d7b6d;
}

:-ms-input-placeholder {
  color: #7d7b6d;
}

@media (min-width: 1200px) {
  .container {
    max-width: 2000px;
  }
}
