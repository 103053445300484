/*-------------------------
Typography
----------------------*/

@import url('https://fonts.googleapis.com/css?family=Oswald:300,400,500,600,700|Source+Sans+Pro:300,400,400i,600,600i,700&display=swap');
@import url(https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@2.0/nanumsquare.css);

@font-face {
  font-family: 'NotoSansKR-Regular';
  src: url(../font/NotoSansKR-Regular.ttf);
}

@font-face {
  font-family: 'MALGUN';
  src: url(../font/MALGUN.TTF);
}

.assessButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.childButton {
  margin-top: 50px;
  width: 150px;
  height: 50px;
}
.css-ww26ez-MuiList-root {
  max-width: 800px !important;
  min-width: 800px;
}
.lsitText {
  font-size: 14px;
  line-height: 20px;
}
.radioButton {
  margin-right: 10px;
}
.progressBox {
  width: 90%;
  height: auto;
  display: flex;
  justify-content: center;
}
.progressPage {
  text-align: center;
}

.progressNotice {
  font-weight: bold;
  color: #1159bd;
  margin-top: 25px;
  width: 100%;
  padding-right: 130px;
}

.progressNotice ul {
  list-style-type: disc;
}

.progressNotice ul li {
  font-size: 20px;
  line-height: 1.8;
  color: #666666;
}

.assessBorder {
  // border-bottom: 1px solid #1159bd;
  width: 500px;
  margin-top: 20px;
}

.endNotice {
  font-weight: bold;
  color: #1159bd;
  margin-top: 25px;
  width: 100%;
  text-align: center;
}

.endNotice h2 {
  color: #595959;
}

.endInfo {
  text-align: left;
  width: 50%;
  margin: 0 auto;
  padding-left: 100px;
}

.css-1p823my-MuiListItem-root {
  height: 58px;
}
.css-ww26ez-MuiList-root {
  margin-top: 58px !important;
}
.pt--140 {
  padding-top: 50px !important;
}

.confirmAlert {
  width: 500px;
}
.confirmText {
  font-size: 15px;
}
.right {
  text-align: right;
}
.notice {
  text-align: center;
  font-weight: bold;
  color: #1159bd;
  font-size: 18px;
  margin-bottom: 5px !important;
  margin-left: 50px !important;
}
.css-ypiqx9-MuiDialogContent-root {
  padding-bottom: 10px !important;
}
.noticeTitle {
  color: #ffffff;
  text-align: center;
  position: absolute;
  top: 30%;
  left: 900px;
}
.noticeBorder {
  border: 0.1px solid;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
}

.assessNotice {
  width: 60%;
  margin-bottom: 5px;
  margin-left: 20%;
}

.assessNotice ul {
  list-style-type: disc;
}

.assessNotice ul li {
  font-size: 20px;
  line-height: 1.8;
}

.assessNotice .red {
  color: #ff0000;
  font-weight: bold;
}

.assessNotice .blue {
  color: #0070c0;
  font-weight: bold;
}

.startButton {
  display: inline-block;
  width: 100%;
  margin-top: 40px;
  text-align: center;
}

// 관리자 Page

* {
  font-family: 'NotoSansKR-Regular' !important;
  // font-weight: bold !important;
}

.tbodyStyle {
  max-height: 300px; /* 테이블의 최대 높이 설정 */
  overflow-y: auto; /* 세로 스크롤이 필요한 경우에만 스크롤 표시 */
}
.boxStyle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.5);
  padding: 30px;
}

html {
  overflow-x: hidden;
}
.css-pjjt8i-MuiToolbar-root {
  height: 96px;
}
.title {
  width: 90%;
  font-size: 25px;
  font-weight: bold;
  margin-left: 5%;
  margin-right: auto;
  margin-top: 50px;
}
.testList {
  display: inline-block;
}
.testInfotitle {
  display: inline-block;
  margin: 0 10px; /* 텍스트 좌우 간격 조절 */
}
.testKeyList {
  margin: 50px;
}

.moreVertIcon {
  // background-color: #e8ebef !important;
  transition: all 0.5s ease 0s;
  text-transform: capitalize;
  color: gray !important;
  border: 0 none;
  border-radius: 50px !important;
  // background: #dadee3 none repeat scroll 0 0;
  &:hover {
    color: #fff;
    background: #dadee3 none repeat scroll 0 0 !important;
  }
}
.tableStyle {
  color: rgba(0, 0, 0, 0.87) !important;
  &:hover {
    color: #fff;
    background: #bebebe33 none repeat scroll 0 0 !important;
  }
}

.testInfolist {
  text-align: center;
  margin: 30px;
  display: flex;
  justify-content: center; /* 수평 중앙 정렬 */
  align-items: center; /* 수직 중앙 정렬 */
}
.testInfoBox {
  margin-bottom: 50px;
  text-align: center;
}
.testType {
  font-weight: bold;
  color: #1752b4;
  margin-left: 20px;
}

.testInfo {
  font-weight: bold;
}

.testInfotitle {
  margin-left: 70px;
  display: ruby;
}
.css-c0pzr3-MuiAutocomplete-root {
  margin-left: 5px;
}

.tab {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  margin-bottom: 100px;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: #001232 !important;
  font-size: 16px;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #001232 !important;
  font-weight: bold;
}

.input {
  margin-left: 50px !important;
  margin-right: 50px !important;
  width: 350px;
  background-color: white;
  margin-bottom: 10px !important;
}
.theadStyle th {
  background-color: white !important;
  text-align: center;
}

.table-cell {
  border: none;
  padding: 8px;
  text-align: center !important;
}
.modalDatas {
  margin: 20px;
}
.modalText {
  font-size: 16px;
  font-weight: bold !important;
  margin-left: 40px !important;
  margin-right: 40px !important;
  padding: 10px;
}

#modal {
  width: auto;
  padding-bottom: 40px !important;
  border: 0px !important;
  padding-top: 30px !important;
}
.modalTitle {
  color: #001232 !important;
  font-weight: bold !important;
  text-align: center;
  margin-bottom: 10px !important;
  font-size: 23px;
  top: -2px;
}
.MuiBackdrop-root {
  background-color: #ffffff00 !important;
}

.css-17ffvgn-MuiTypography-root {
  padding: 5px !important;
}

.typography {
  height: auto;
  padding: 0 !important;
}

input[type='password'] {
  font-family: '맑은 고딕', 'MALGUN';
}

.css-1wnsr1i {
  padding: 0 !important;
}

.css-1whpjm6-MuiToolbar-root {
  padding: 28px;
}
.MuiBox-root css-7822g1 {
  padding-bottom: 50px;
}

.css-7822g1 {
  // margin-left: auto;
  // margin-right: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100% !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  font-size: 16px !important;
}
tr:nth-child(even) {
  background-color: #f9f9f9;
}

.css-2pbtu6-MuiTypography-root {
  padding: 20px;
}

.inline {
  display: inline-block;
}

button {
  font-weight: bold !important;
}

.inlineButton {
  margin-left: 10px !important;
  float: right;
  position: relative !important;
  top: 10.75px;
  background-color: #c9e9f1 !important;

  font-size: 19px;

  display: block;
  padding: 0 8px;

  transition: all 0.5s ease 0s;
  text-transform: capitalize;

  color: #1075a3 !important;
  border: 0 none;
  border-radius: 30px !important;
  background: #a3dfec none repeat scroll 0 0;
  &:hover {
    color: #fff;
    background: #a3dfec none repeat scroll 0 0 !important;
  }
}
.stateGreen {
  background-color: #ceebdd !important;
  transition: all 0.5s ease 0s;
  text-transform: capitalize;
  color: #118d57 !important;
  border: 0 none;
  border-radius: 30px !important;
  background: #aee3c4 none repeat scroll 0 0;
  &:hover {
    color: #fff;
    background: #aee3c4 none repeat scroll 0 0 !important;
  }
}
.greenButton {
  margin-left: 10px !important;
  float: right;
  position: relative !important;
  top: 10.75px;
  background-color: #ceebdd !important;

  font-size: 19px;

  display: block;
  padding: 0 8px;

  transition: all 0.5s ease 0s;
  text-transform: capitalize;

  color: #118d57 !important;
  border: 0 none;
  border-radius: 30px !important;
  background: #aee3c4 none repeat scroll 0 0;
  &:hover {
    color: #fff;
    background: #aee3c4 none repeat scroll 0 0 !important;
  }
}
.stateRed {
  background-color: #f2d9d6 !important;
  transition: all 0.5s ease 0s;
  text-transform: capitalize;
  color: #b71d18 !important;
  border: 0 none;
  border-radius: 30px !important;
  background: #f5c0b5 none repeat scroll 0 0;
  &:hover {
    color: #fff;
    background: #f5c0b5 none repeat scroll 0 0 !important;
  }
}
.redButton {
  margin-left: 10px !important;
  float: right;
  position: relative !important;
  top: 10.75px;
  background-color: #f2d9d6 !important;

  font-size: 19px;

  display: block;
  padding: 0 8px;

  transition: all 0.5s ease 0s;
  text-transform: capitalize;

  color: #b71d18 !important;
  border: 0 none;
  border-radius: 30px !important;
  background: #f5c0b5 none repeat scroll 0 0;
  &:hover {
    color: #fff;
    background: #f5c0b5 none repeat scroll 0 0 !important;
  }
}

.stateGray {
  background-color: #e8ebef !important;
  transition: all 0.5s ease 0s;
  text-transform: capitalize;
  color: #212b36 !important;
  border: 0 none;
  border-radius: 30px !important;
  background: #dadee3 none repeat scroll 0 0;
  &:hover {
    color: #fff;
    background: #dadee3 none repeat scroll 0 0 !important;
  }
}

.grayButton {
  margin-left: 10px !important;
  float: right;
  position: relative !important;
  top: 10.75px;
  background-color: #e8ebef !important;

  font-size: 19px;

  display: block;
  padding: 0 8px;

  transition: all 0.5s ease 0s;
  text-transform: capitalize;

  color: #212b36 !important;
  border: 0 none;
  border-radius: 30px !important;
  background: #dadee3 none repeat scroll 0 0;
  &:hover {
    color: #fff;
    background: #dadee3 none repeat scroll 0 0 !important;
  }
}

.modalButton {
  display: flex; /* Flexbox 레이아웃 적용 */
  justify-content: center; /* 가로 중앙 정렬 */
  align-items: center; /* 세로 중앙 정렬 */
  margin: 10px;
}

.rowState {
  display: flex;
  justify-content: center;
}

.cancleButton {
  margin-left: 10px !important;
  float: right;
  position: relative !important;
  top: 10.75px;
  background-color: #f2d9d6 !important;

  font-size: 19px;

  display: block;
  padding: 0 8px;

  transition: all 0.5s ease 0s;
  text-transform: capitalize;

  color: #b71d18 !important;
  border: 0 none;
  border-radius: 30px !important;
  background: #f5c0b5 none repeat scroll 0 0;
  &:hover {
    color: #fff;
    background: #f5c0b5 none repeat scroll 0 0 !important;
  }
}

.submitButton {
  margin-left: 10px !important;
  float: right;
  position: relative !important;
  top: 10.75px;
  background-color: #c9e9f1 !important;

  font-size: 19px;

  display: block;
  padding: 0 8px;

  transition: all 0.5s ease 0s;
  text-transform: capitalize;

  color: #1075a3 !important;
  border: 0 none;
  border-radius: 30px !important;
  background: #a3dfec none repeat scroll 0 0;
  &:hover {
    color: #fff;
    background: #a3dfec none repeat scroll 0 0 !important;
  }
}

thead th {
  background-color: #001232 !important;
  border-right: 1px solid #1752b4;
}

.thead tr th span {
  color: white !important;
  padding-left: 10px;
}

body {
  font-family: $body-font;
  font-size: $body-font-size;
  font-weight: 400;
  line-height: $body-line-height;

  overflow-x: hidden;

  color: $body-color;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  line-height: 30px;

  color: #1a1200;
}

::-webkit-input-placeholder {
  color: $body-color;
}

:-moz-placeholder {
  color: $body-color;
}

::-moz-placeholder {
  color: $body-color;
}

:-ms-input-placeholder {
  color: $body-color;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
address,
p,
pre,
blockquote,
dl,
dd,
menu,
ol,
ul,
caption,
hr {
  margin: 0;
  margin-bottom: 15px;
}

table {
  margin: 0;
  border-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $heading-font;
  line-height: 1.3;
}

h1,
.h1 {
  font-size: 48px;
}

h2,
.h2 {
  font-size: 30px;
}

h3,
.h3 {
  font-size: 22px;
}

h4,
.h4 {
  font-size: 20px;
}

h5,
.h5 {
  font-size: 18px;
}

h6,
.h6 {
  font-size: 16px;
}

@media #{$md-layout} {
  h1,
  .h1 {
    font-size: 36px;
  }
  h2,
  .h2 {
    font-size: 26px;
  }
  h3,
  .h3 {
    font-size: 20px;
  }
}

@media #{$sm-layout} {
  h1,
  .h1 {
    font-size: 32px;
  }
  h2,
  .h2 {
    font-size: 24px;
  }
  h3,
  .h3 {
    font-size: 18px;
  }
}

h1,
.h1,
h2,
.h2 {
  font-weight: 700;
}

h3,
.h3,
h4,
.h4 {
  font-weight: 600;
}

h5,
.h5,
h6,
.h6 {
  font-weight: 500;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;

  outline: none;
}

.css-1o2jng6-MuiOutlinedInput-notchedOutline {
  top: 0 !important;
}

.css-13xfq8m-MuiTabPanel-root {
  padding: 0 !important;
}

.css-1ic0da0-MuiPaper-root-MuiDrawer-paper {
  border-top: 0 !important;
}

.css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon {
  color: rgb(255 255 255 / 60%) !important;
}

.groupHeader td + td {
  background-color: #cececea3;
  color: #1a1a1a;
  font-weight: bold;
}

.areaRow {
  // background-color: #adadad !important;
}

.areaRow td {
  color: #1a1a1a;
  font-weight: bold;
  background-color: #cececea3;
}

.toastui-editor-toolbar-item-wrapper {
  margin: 0;
}

.service__wrap {
  padding: 0 200px !important;
}

@media #{$qhd-layout} {
  .assessSection {
    width: 50%;
    margin: 0 auto;
  }
}

@media #{$fhd-layout} {
  .assessSection {
    width: 67%;
    margin: 0 auto;
  }
}

.listItem {
  height: 58px !important;
}

.css-hyxlzm {
  color: rgba(0, 0, 0, 0.6);
}
