/*===== Colors =====//*/
$theme-color: #001232;
$body-color: #666666;
$heading-color: #333333;

/*===== Font Family =====//*/
$body-font: 'Source Sans Pro', sans-serif;
$heading-font: 'Oswald', sans-serif;

/*===== Line Height =====//*/
$body-line-height: 24px;

/*===== Radius  =====//*/
$theme-radius: 3px;

/*==== Font size =====//*/
$body-font-size: 16px;

/*===== Border color ======//*/
$border-color-light: #eeeeee;
$border-color-medium: #aaaaaa;
$border-color-dark: #333333;

/*===== Alerts color  =====//*/
$color-primary: #337ab7;
$color-success: #5cb85c;
$color-info: #5bc0de;
$color-warning: #f0ad4e;
$color-danger: #d9534f;

/*===== Social Colors =====//*/
$facebook: #3b5998;
$twitter: #00acee;
$google-plus: #dd4b39;
$pinterest: #c8232c;
$linkedin: #0e76a8;
$flickr: #ff0084;
$dribbble: #ea4c89;
$google: #dd4b39;
$skype: #00aff0;
$youtube: #c4302b;
$vimeo: #86c9ef;
$yahoo: #720e9e;
$paypal: #00588b;
$delicious: #205cc0;
$flattr: #f67c1a;
$android: #a4c639;
$tumblr: #34526f;
$wikipedia: #333333;
$stumbleupon: #f74425;
$foursquare: #25a0ca;
$digg: #191919;
$spotify: #81b71a;
$reddit: #c6c6c6;
$cc: #688527;
$vk: #2b587a;
$rss: #ee802f;
$podcast: #e4b21b;
$dropbox: #3d9ae8;
$github: #171515;
$soundcloud: #ff7700;
$wordpress: #1e8cbe;
$yelp: #c41200;
$scribd: #666666;
$cc-stripe: #008cdd;
$drupal: #27537a;
$get-pocket: #ee4056;
$bitbucket: #0e4984;
$stack-overflow: #ef8236;
$hacker-news: #ff6600;
$xing: #126567;
$instagram: #3f729b;
$quora: #a82400;
$openid: #e16309;
$steam: #111111;
$amazon: #e47911;

/* Layouts*/
$qhd-layout: 'only screen and (min-width: 2560px)';
$fhd-layout: 'only screen and (min-width: 1920px) and (max-width: 2559px)';
$xx-layout: 'only screen and (min-width: 1600px) and (max-width: 1919px)';
$xlg-layout: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$lg-layout: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout: 'only screen and (min-width: 768px) and (max-width: 991px)';
$sm-layout: 'only screen and (max-width: 767px)';
$xs-layout: 'only screen and (max-width: 575px)';
$xxs-layout: 'only screen and (max-width: 420px)';
