/*-------------------
    Currency Styles
--------------------*/

.dg__get__currency__area {
    position: relative;
}

.images-2 {
    position: absolute;
    top: 50%;
    right: 90px;

    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.images-2 img {
    width: 100%;
}

.get__currency {
    h2 {
        font-size: 24px;

        margin-bottom: 9px;

        text-transform: uppercase;

        color: #1856B8;
    }
    h3 {
        font-size: 24px;
        font-weight: 700;

        margin-bottom: 44px;

        text-transform: uppercase;

        color: #1856B8;
    }
}

.currency__form {
    display: flex;

    margin-right: -15px;
    margin-left: -15px;
}

.currency__box {
    .input__box {
        margin-bottom: 30px;
        span {
            font-size: 16px;

            display: block;

            margin-bottom: 15px;

            color: #648DD2;
        }
        input {
            width: 100%;
            height: 44px;
            padding: 0 20px;

            border: 1px solid #D6E3FA;
            border-radius: 4px;
            outline: none;
        }
        &.name {
            flex-basis: 50%;

            margin: 0 15px 30px;
        }
    }
}

.currency__box {
    .submit__btn {
        button {
            font-size: 16px;
            line-height: 38px;

            display: inline-block;

            height: 40px;
            padding: 0 30px;

            transition: all 0.3s ease 0s;
            text-transform: uppercase;

            color: #FFF;
            border: 1px solid #1852B5;
            border-radius: 4px;
            background: #1852B5 none repeat scroll 0 0;
            &:hover {
                color: #1852B5;
                border: 1px solid #1852B5;
                background: transparent none repeat scroll 0 0;
            }
        }
    }
}

/* Responsive Css*/

@media #{$lg-layout} {
    .images-2 {
        right: 42px;

        width: 45%;
    }
}

@media #{$md-layout} {
    .images-2 {
        position: inherit;
        top: inherit;
        right: 0;

        margin-top: 64px;

        -webkit-transform: inherit;
                transform: inherit;
    }
}

@media #{$sm-layout} {
    .images-2 {
        position: inherit;
        top: inherit;
        right: 0;

        margin-top: 64px;

        -webkit-transform: inherit;
                transform: inherit;
    }
    .currency__form {
        display: block;
    }
}

@media #{$xlg-layout} {
    .images-2 {
        right: 0;

        width: 49%;
    }
}

@media #{$xx-layout} {
    .images-2 {
        right: 0;

        width: 49%;
    }
}
